export function floor(value) {
  if (value === 0) return "EG"

  if (value < 0) return value + ". UG"

  if (!value) return ""

  return value + ". OG"
}

export function prettyNumber(value, toFixed, removeTrailingZeros) {
  if (toFixed == null) {
    toFixed = 2
  }

  if (removeTrailingZeros == null) {
    removeTrailingZeros = true
  }

  if (!value) return value

  const parsed = parseFloat(value)
    .toFixed(toFixed)
    .replace(".", ",")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  const re = new RegExp(`,0{${toFixed}}`)

  if (removeTrailingZeros) {
    return parsed.replace(re, "")
  } else {
    return parsed
  }
}

export function prettySpace(value) {
  const prettyValue = prettyNumber(value)
  if (prettyValue) return `${prettyValue} m²`
}

export function numberToCurrency(value, currency, showDecimals) {
  if (currency == null) currency = "€"
  if (showDecimals == null) showDecimals = true
  return formatNumberWithUnit(value, currency, showDecimals)
}
const UNIT_FORMATS = {
  $: [",", ".", "start"],
  USD: [",", ".", "start"],
  "£": [",", ".", "start"],
  GBP: [",", ".", "start"],
  CHF: ["'", ".", "start"],
  "€": [".", ",", "end"],
  EUR: [".", ",", "end"],
  Ft: [".", ",", "end"],
  HUF: [".", ",", "end"],
  AED: [".", ",", "start"],
  "Kč": [".", ",", "end"],
  CZK: [".", ",", "end"],
}

export function formatNumberWithUnit(value, unit, showDecimals = true, precision = 2) {
  if (!value && value != 0) return ""

  const delimiter = UNIT_FORMATS[unit] ? UNIT_FORMATS[unit][0] : "."
  const separator = UNIT_FORMATS[unit] ? UNIT_FORMATS[unit][1] : ","
  const re = `${separator}${_.times(precision, () => "0").join("")}`
  const parts = parseFloat(value)
    .toFixed(precision)
    .replace(".", separator)
    .split(unit === "CHF" ? "." : ",")
  let formattedValue =
    parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, delimiter) + (parts[1] && showDecimals ? separator + parts[1] : "")
  if (_.endsWith(formattedValue, re)) {
    formattedValue = formattedValue.replace(re, "")
  }

  if (UNIT_FORMATS[unit] && UNIT_FORMATS[unit][2] == "start") {
    return `${unit} ${formattedValue}`
  } else {
    return `${formattedValue} ${unit || ""}`.trim()
  }
}

export function rented(value) {
  if (value) return "Ja"
}

export function humanFileSize(size) {
  const i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024))
  const precision = [0, 0, 1, 2, 2][i]
  return (size / Math.pow(1024, i)).toFixed(precision) * 1 + " " + ["Bytes", "KB", "MB", "GB", "TB"][i]
}

export default {
  floor,
  prettyNumber,
  prettySpace,
  numberToCurrency,
  rented,
  humanFileSize,
  formatNumberWithUnit,
}
