import moment from "moment-timezone"
moment.locale("de")
// moment.tz.setDefault(moment.tz.guess() || "Europe/Berlin")

export const datetime = value => {
  if (!value) return ""
  return moment(value).format("DD.MM.YYYY HH:mm")
}

export const longDatetime = value => {
  if (!value) return ""
  return moment(value).format("dddd, Do MMMM YYYY, H:mm")
}

export const date = (value, format = "DD.MM.YYYY") => {
  if (!value) return ""
  return moment(value).format(format)
}

export const time = value => {
  if (!value) return ""
  return moment(value).format("HH:mm")
}

export const calendar = value => {
  if (!value) return ""
  return moment(value).calendar(null, {
    lastDay: "DD.MM.YYYY",
    sameDay: "HH:mm",
    nextDay: "DD.MM.YYYY",
    lastWeek: "DD.MM.YYYY",
    nextWeek: "DD.MM.YYYY",
    sameElse: "DD.MM.YYYY",
  })
}

export const emailCalendar = value => {
  if (!value) return ""
  return moment(value).calendar(null, {
    lastDay: "D. MMM HH:mm",
    sameDay: "HH:mm",
    nextDay: "D. MMM HH:mm",
    lastWeek: "D. MMM HH:mm",
    nextWeek: "D. MMM HH:mm",
    sameElse: "D. MMM HH:mm",
  })
}

export const age = value => {
  if (!value) return ""
  return moment().diff(value, "years")
}

export const isInPast = value => {
  if (!value) return false
  return moment(value).isBefore(moment())
}

export const isToday = value => {
  if (!value) return false
  return moment(value).isSame(moment(), "day")
}

export default {
  datetime,
  date,
  time,
  calendar,
  emailCalendar,
  longDatetime,
  age,
  isInPast,
  isToday,
}
