import DateFilters from "./date-filters"
import NumberFilters from "./number-filters"
import { clientPath } from "./client-filters"

export const urlify = url => {
  try {
    new URL(url)
    return url
  } catch (e) {
    return `http://${url}`
  }
}

export const phonify = str => {
  if (!str) return
  const number = str
    .replace(/[^0-9,+,(.*)]/g, "")
    .replace(/\(0\)/g, "")
    .replace(/[\(,\)]/g, "")
  return `tel:${number}`
}

export const gmapsUrl = str => {
  if (str) return `https://maps.google.de/maps?hl=de&q=${str}`
}

export const initials = value => {
  if (!value) return ""
  const arr = value.split(" ").filter(o => o.trim())

  if (arr.length === 1) return arr[0][0]

  try {
    return arr[0][0] + arr[1][0]
  } catch {
    return ""
  }
}

export const pluralize = (count, singularWord, pluralWord) => {
  if (count == 1) return `${count} ${singularWord}`
  return `${count} ${pluralWord}`
}

export const simpleFormat = text => {
  if (text) return text.replace(/\n/g, "<br>")
}

export const truncate = (text, limit = 10) => {
  if (text && text.length > limit + 3) return `${text.slice(0, limit)}...`
  return text
}

export const parameterize = text => {
  if (!text) return
  return text.toLowerCase().replace(/ /, "-")
}

export default {
  ...DateFilters,
  ...NumberFilters,
  clientPath,
  urlify,
  phonify,
  gmapsUrl,
  initials,
  pluralize,
  truncate,
  simpleFormat,
  parameterize,
}
